




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL as url } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdOutlineRule, MdQuestionAnswer, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import TabPanel from "../ReusableComponents/TabPanel";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, IconButton, Snackbar, Tab, Tabs } from "@material-ui/core";
import PDF_IMAGE from '../../assets/images/pdf.png';
import './modal.css';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';
import SweetAlert from 'react-bootstrap-sweetalert';
import CloseIcon from '@material-ui/icons/Close';

class ShowRecurso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/recursos',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            faculdade: {},
            recurso: {},
            show_more: false,

            activeTab: 0,
            showModal: false,
            resposta: '',
            show_success: false,

            files: [],
            files_images: [],
            loading_files: false,
            status:'',
            statusQuestao:null
        };
        this.inputRef = React.createRef();

    }


    get_recurso(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${url}api/professor/get_recurso/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_recurso();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, recurso: resp.recurso,
                        resposta: resp.recurso.resposta != null ? resp.recurso.resposta.resposta : '',
                        status:resp.recurso.resposta!=null?resp.recurso.resposta.status:'',
                        statusQuestao:resp.recurso.questao.status,
                        files: resp.recurso.resposta != null ? resp.recurso.resposta.files : [],
                        files_images: resp.recurso.resposta != null ? resp.recurso.resposta.files.map((item, id) => item.path) : [],
                    });
                    // if (resp.recurso != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        if(moment().format('YYYY-MM-DD HH:mm:ss')>this.state.recurso.data_limite){
            return;
        }
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('resposta', this.state.resposta);
        form.append('status', this.state.status);
        form.append('anulada', this.state.statusQuestao==null?'N':'A');


        form.append('recurso_id', this.props.match.params.id);

        this.state.files.map((file, id) => {
            if (file.id == undefined) {
                form.append('files[]', file);
            }
            else {
                form.append('files_exists[]', file.id);
            }
        })



        fetch(`${url}api/professor/store_resposta_recurso`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, show_success: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }






    componentDidMount() {
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.get_recurso(this.props.match.params.id);
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div style={{ position: "relative" }}>
                <div className="card">
                    <div className="card-body">
                        {this.state.recurso.id!=undefined && <div style={{ float: 'right' }}>
                            <DefaultButton
                                bg="secondary"
                                text="Voltar"
                                icon={<RiArrowGoBackLine />}
                                to={`/recursos/`+this.state.recurso.questao.id}
                            />
                        </div>}
                        <div className="flex jcsb">
                            <h4 className="header">{this.state.recurso.name}</h4>
                        </div>

                        {<div id="myModalCam" className="modal_2">

                            <span onClick={() => {
                                var modal = document.getElementById("myModalCam");
                                modal.style.display = "none";
                            }} className="close">&times;</span>

                            <img className="modal-content_2" id="imgShow" />

                            <div id="caption"></div>
                        </div>}


                        <TextReadMore title="Data e hora limite: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                            {moment(this.state.recurso.data_limite).format('DD/MM/YYYY HH:mm')}
                        </TextReadMore>
                        <TextReadMore title="Descrição dos fatos: " element="div" limit={120} style={{ width: "100%", marginBottom: 0, whiteSpace: 'pre' }}>
                            {this.state.recurso.description}
                        </TextReadMore>

                        <TextReadMore title="Fundamentação do pedido: " element="div" limit={120} style={{ width: "100%", marginBottom: 0,marginTop:10, whiteSpace: 'pre' }}>
                            {this.state.recurso.fundamentacao}
                        </TextReadMore>

                        <TextReadMore title="Pedido realizado: " element="div" limit={120} style={{ width: "100%", marginBottom: 0,marginTop:10, whiteSpace: 'pre' }}>
                            {this.state.recurso.pedido_realizado}
                        </TextReadMore>

                        <a hidden id="link_pdf" target={'_blank'} href="#"></a>

                        {this.state.recurso.files.length > 0 && <Form.Label >Imagem(s)/arquivo(s) do recurso:</Form.Label>}
                        <div className='row' style={{ marginRight: 0, marginLeft: 0 }}>
                            {this.state.recurso.files.map((item, id) => (
                                <div key={id} style={{ width: 100, marginRight: 10, marginTop: 10, position: 'relative' }}>
                                    {!item.name.includes('.pdf') && <img
                                        onClick={() => {
                                            var modal = document.getElementById("myModalCam");
                                            var modalImg = document.getElementById("imgShow");
                                            modalImg.src = item.path;
                                            modal.style.display = "block";
                                        }}
                                        className="myImg"
                                        src={item.path}
                                        style={{ height: 75, width: 100, objectFit: 'contain' }}
                                    />}
                                    {item.name.includes('.pdf') && <img
                                        onClick={() => {
                                            document.getElementById('link_pdf').href = item.path;
                                            document.getElementById('link_pdf').click();
                                        }}
                                        className="myImg"
                                        src={PDF_IMAGE}
                                        style={{ height: 75, width: 100, objectFit: 'contain' }}
                                    />}
                                    <div style={{ width: 100 }}>
                                        <p style={{ marginBottom: 0, textAlign: 'center', marginTop: 5, fontSize: 11 }}>{item.name}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                        <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.recurso.questao} />

                        <div className="btn btn-outline-primary btn-lg btn-block margin-bottom" style={{ marginTop: '1rem', width: '100%' }} onClick={() => {
                            this.setState({ showModal: true });

                        }}>

                            <p style={{ marginRight: "2px", marginTop: 0, marginBottom: 0, textAlign: 'center' }}> <b style={{ float: "left", marginRight: "2px", color: 'black', marginRight: 2 }}></b> <MdQuestionAnswer /> {'Ver questão'} </p>
                        </div>
                        {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>

                            <div className="flex fdcolumn flex1 margin-bottom ">
                                <Form.Label >Resposta <Attention /></Form.Label>
                                <Input
                                    placeholder="Escreva a resposta do recurso"
                                    disabled={moment().format('YYYY-MM-DD HH:mm:ss')>this.state.recurso.data_limite}

                                    value={this.state.resposta}
                                    onChange={(e) => this.setState({ resposta: e.target.value })}
                                    required
                                    as="textarea"
                                />
                            </div>

                            <div className="flex fdcolumn flex1 margin-bottom ">
                                <Form.Label >Status <Attention /></Form.Label>
                                <div >
                                    <Form.Check
                                        disabled={moment().format('YYYY-MM-DD HH:mm:ss')>this.state.recurso.data_limite}

                                        inline
                                        label="Deferido"
                                        name="group1"
                                        type={'radio'}
                                        id={`inline-1`}
                                        onChange={(e)=>{
                                            console.log(e.target.value);
                                            console.log(e.target.checked);
                                            this.setState({status:'deferido'})
                                        }}
                                        checked={this.state.status=='deferido'}

                                    />
                                    <Form.Check
                                        disabled={moment().format('YYYY-MM-DD HH:mm:ss')>this.state.recurso.data_limite}
                                        inline
                                        onChange={(e)=>{
                                            console.log(e.target.value);
                                            console.log(e.target.checked);
                                            this.setState({status:'indeferido'})
                                        }}
                                        label="Indeferido"
                                        checked={this.state.status=='indeferido'}
                                        name="group1"
                                        type={'radio'}
                                        id={`inline-2`}
                                    />
                                   
                                </div>
                            </div>

                            <div className="flex fdcolumn flex1 margin-bottom ">
                                <Form.Label >Anular Questão? <Attention /></Form.Label>
                                <div >
                                    <Form.Check
                                        disabled={moment().format('YYYY-MM-DD HH:mm:ss')>this.state.recurso.data_limite}

                                        inline
                                        label="Sim"
                                        name="group2"
                                        type={'radio'}
                                        id={`inline-1`}
                                        onChange={(e)=>{
                                            console.log(e.target.value);
                                            console.log(e.target.checked);
                                            this.setState({statusQuestao:'A'})
                                        }}
                                        checked={this.state.statusQuestao=='A'}

                                    />
                                    <Form.Check
                                        disabled={moment().format('YYYY-MM-DD HH:mm:ss')>this.state.recurso.data_limite}
                                        inline
                                        onChange={(e)=>{
                                            console.log(e.target.value);
                                            console.log(e.target.checked);
                                            this.setState({statusQuestao:null})
                                        }}
                                        label="Não"
                                        checked={this.state.statusQuestao==null}
                                        name="group2"
                                        type={'radio'}
                                        id={`inline-2`}
                                    />
                                   
                                </div>
                            </div>

                            <div className="flex fdcolumn flex1 margin-bottom ">
                                <Form.Label >Imagens/arquivos de resposta (Máximo 5 arquivos)</Form.Label>
                                <Input
                                    type="file"
                                    accept="image/*,application/pdf"
                                    multiple
                                    onChange={(e) => {
                                        this.setState({ loading_files: true });
                                        let files = [...e.target.files];
                                        let files_images = [...this.state.files_images];

                                        console.log(files);
                                        // SetInputValue(file);

                                        // PreviewImage(file);
                                        // (props.ImageChange && props.ImageChange(file));
                                        if (files && FileReader) {

                                            files.map((file, id) => {
                                                files_images.push(URL.createObjectURL(file));

                                            })
                                            let files_actual = [...this.state.files, ...files];
                                            this.setState({ loading_files: false, files: files_actual.filter((item, id) => id < 5), files_images: files_images.filter((item, id) => id < 5) });
                                        }
                                        this.inputRef.current.value = '';
                                    }}
                                    ref={this.inputRef}
                                />
                                <div className='row' style={{ marginRight: 0, marginLeft: 0 }}>
                                    {this.state.files.map((item, id) => (
                                        <div key={id} style={{ width: 100, marginRight: 10, marginTop: 10, position: 'relative' }}>
                                            {!item.name.includes('.pdf') && <img
                                                src={this.state.files_images[id]}
                                                style={{ height: 75, width: 100, objectFit: 'contain' }}
                                            />}
                                            {item.name.includes('.pdf') && <img
                                                src={PDF_IMAGE}
                                                style={{ height: 75, width: 100, objectFit: 'contain' }}
                                            />}
                                            <div style={{ width: 100 }}>
                                                <p style={{ marginBottom: 0, textAlign: 'center', marginTop: 5, fontSize: 11 }}>{item.name}</p>
                                            </div>
                                            <IconButton
                                                style={{ background: 'rgba(0,0,0,0.5)', position: 'absolute', top: 0, right: 0, width: 20, height: 20 }}
                                                id="remove-button"
                                                size="small"
                                                onClick={() => {
                                                    let files = this.state.files.filter((item2, id2) => id != id2);

                                                    let files_images = this.state.files_images.filter((item2, id2) => id != id2);

                                                    this.setState({ files, files_images });

                                                }}
                                            >
                                                <CloseIcon style={{
                                                    width: "0.7em",
                                                    color: "white"
                                                }} />
                                            </IconButton>
                                        </div>
                                    ))}

                                </div>

                            </div>

                            {moment().format('YYYY-MM-DD HH:mm:ss')<=this.state.recurso.data_limite && this.state.loading_save == false && <div className="w100 inline-flex justify-content-end margin-top-1">

                                <DefaultButton
                                    type="submit"
                                    bg="confirm"
                                    text="Salvar resposta"
                                    disabled={this.state.loading_save}
                                    loadingtext="Salvando resposta"
                                    loading={this.state.loading_save}
                                />
                            </div>}

                            {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-end align-items-center margin-top-1" >
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                        </Form>

                        {/* <div style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre',height:this.state.show_more==false?100:'fit-content',overflowY:this.state.show_more==false? 'hidden':'visible' }}>
                            <b style={{ float: "left", marginRight: "2px" }}>{'Regras:'} </b>
                            <br/>
                            <div dangerouslySetInnerHTML={{ __html: this.state.recurso.regras }}>
                            </div>
                        </div> */}
                        {/* <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div className="course-banner" style={{
                                backgroundImage: `url(${URL+'storage/'+ this.state.faculdade.logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat:'no-repeat',
                                backgroundPositionX:'center',
                                width: "100%",
                                height: "200px",
                                borderRadius: "7px"
                            }} />
                        </div> */}

                        {/* <Tabs
                            value={this.state.activeTab}
                            onChange={(_, value) => {
                                this.setState({ activeTab: value });
                                this.props.history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label="Atribuições" />
                          


                        </Tabs>
                        <TabPanel value={this.state.activeTab} index={0}>
                            <Atribuicoes recurso_id={this.state.recurso.id} recurso_name={this.state.recurso.name} />
                        </TabPanel> */}

                        <SweetAlert
                            success
                            title={"Sucesso"}
                            onConfirm={() => {
                                this.setState({ show_success: false });
                                // this.delete_banner(this.state.banner_to_delete);
                                this.props.history.push({ pathname:`/recursos/`+this.state.recurso.questao.id });

                            }}
                            onCancel={() => {
                                this.setState({ show_success: false });
                                this.props.history.push({ pathname:`/recursos/`+this.state.recurso.questao.id });

                            }}
                            show={this.state.show_success}
                            confirmBtnText='Ok'
                            confirmBtnBsStyle="success"

                        >
                            Resposta salva com sucesso!
                        </SweetAlert>

                    </div>
                </div>

            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowRecurso);


