
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ModalDemanda from './ModalDemanda';
import { BsFillEyeFill } from 'react-icons/bs';


class EditarDemanda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/demandas',
            loading_screen: true,
            roles: [],
            name: '',
            descricao: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,

            fim: moment().add(1, 'day'),
            hora_inicio: moment(),
            hora_fim: moment().add(1, 'hour'),
            inicio: moment(),
            categorias: [],
            professores: [],

            categoria_search: '',
            show_autocomplete_categoria: false,
            loading_search_categoria: false,
            categorias_select: {},
            showModal: false,
            categoriaSelect: null

        };


    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });

        // if (Object.values(this.state.categorias_select).length == 0) {
        //     this.setState({ loading_save: false, msgErro: 'A demanda deve ter pelo menos uma categoria selecionada' });
        //     return;
        // }
       
        fetch(`${URL}api/faculdade/update_demanda`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                name:this.state.name,
                descricao:this.state.descricao,
                fim:this.state.fim.format('DD/MM/YYYY'),
                hora_fim:this.state.hora_fim.format('HH:mm'),
                demanda_id:this.props.match.params.id
                // categorias:Object.values(this.state.categorias_select)
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_demanda(this.props.match.params.id);
    }


    get_demanda(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_demanda/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, categorias: resp.categorias,
                        professores: resp.professores,
                        name:resp.demanda.name,
                        descricao:resp.demanda.descricao,
                        fim:moment(resp.demanda.data_encerramento),
                        hora_fim:moment(resp.demanda.data_encerramento),
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    select_categoria(item) {
        let categorias = { ...this.state.categorias_select };
        categorias[item.id] = item;
        this.setState({ categoria_search: '', categorias_select: categorias })
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar Demanda</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre as demandas do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}

                                {this.state.showModal == true && <ModalDemanda showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} categoria={this.state.categoriaSelect} professores_all={this.state.professores} OnFinish={(professores, categoria) => {
                                    categoria.professores = professores;
                                    this.select_categoria(categoria);
                                }} />}

                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder="Nome da demanda"
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Descrição </Form.Label>
                                            <Input
                                                placeholder="Descrição da demanda"
                                                value={this.state.descricao}
                                                onChange={(e) => this.setState({ descricao: e.target.value })}
                                                required
                                                as="textarea"
                                            />
                                        </div>
                                    </InputGroup>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>

                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom me-3">
                                                <Form.Label>Data de encerramento <Attention /></Form.Label>
                                                <DatePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Data inválida"
                                                    ampm={false}
                                                    minDate={moment()}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    format="DD/MM/YYYY"
                                                    value={this.state.fim}
                                                    onChange={(date) => { this.setState({ fim: date }) }}

                                                />
                                            </div>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Horário de encerramento <Attention /></Form.Label>
                                                <TimePicker
                                                    autoOk
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                    invalidDateMessage="Horário inválido"
                                                    ampm={false}
                                                    id="time-picker"
                                                    cancelLabel="Cancelar"
                                                    clearLabel="Limpar"
                                                    label=""
                                                    value={this.state.hora_fim}
                                                    onChange={(date) => { this.setState({ hora_fim: date }) }}

                                                />
                                            </div>
                                        </InputGroup>
                                    </MuiPickersUtilsProvider>

                                   
                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to="/demandas"
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar demanda"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando demanda"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarDemanda);


