import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart, FaWineBottle, FaUserAlt, FaVideo, FaTruckMoving, FaBriefcase, FaFolderPlus, FaCog, FaChartLine,FaUser, FaQuestion } from 'react-icons/fa';
import { GiRead } from "react-icons/gi";
import Logo from '../assets/images/logo-ontest-branco.png';
import { Link, NavLink } from 'react-router-dom';
import { MdCopyright,MdOutlineQuestionAnswer,MdApartment, MdQuestionAnswer } from 'react-icons/md';
import { useSelector } from 'react-redux';

const Aside = ({ image, collapsed, toggled, handleToggleSidebar }) => {
  // #084808
  const user_role = useSelector(store => store.AppReducer.user.role);
  return (
    <ProSidebar
      image={false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      style={{ position: 'fixed' }}
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader style={{ background: '#222d32', height: 70 }}>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center'
          }}
        >
          {collapsed == true && <span>
            <img src={Logo} alt="" height='80' style={{
              height: '80px',
              width: '90%',
              objectFit: 'contain'
            }} />
          </span>}
          {collapsed == false && <i>
            <img className="img-fluid" src={Logo} alt="" height="100" style={{ height: '40px' }} />
          </i>}
        </div>
      </SidebarHeader>

      <SidebarContent style={{ background: '#222d32' }}>
        <Menu iconShape="circle">
          <MenuItem
            icon={<FaList />}
          >

            <NavLink exact to="/" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Demandas")}</NavLink>
          </MenuItem>

          <MenuItem
            icon={<FaList />}
          >

            <NavLink exact to="/recursos" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Recursos de Questão")}</NavLink>
          </MenuItem>

          <MenuItem
            icon={<MdOutlineQuestionAnswer />}
          >

            <NavLink exact to="/disciplinas" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Lista de Questões")}</NavLink>
          </MenuItem>

          {/* <MenuItem
            icon={<MdOutlineQuestionAnswer />}
          >

            <NavLink exact to="/categorias_questoes" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Categorias de Questões")}</NavLink>
          </MenuItem>

          <MenuItem
            icon={<FaQuestion />}
          >

            <NavLink exact to="/questionarios" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Questionários")}</NavLink>
          </MenuItem> */}

          

          
{/* 
          {<SubMenu title="Banca de Questões" icon={<MdQuestionAnswer />}>
            <MenuItem icon={<FaUser />}>
              <NavLink to="/professores" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Professores</NavLink>
            </MenuItem>
            <MenuItem icon={<FaList />}>
              <NavLink to="/demandas" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Demandas</NavLink>
            </MenuItem>
          </SubMenu>} */}


          
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center', background: '#222d32' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://www.ontest.com.br"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <MdCopyright />
            <span> {'CopyRight OnTeste 2022'}</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
