import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import Attention from "../ReusableComponents/Attention";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiCloseFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const ModalDemanda = props => {
    const { processo_name, showModal, setShowModal, categoria, professores_all, OnFinish } = props;
    const [professores_selecionadas, setprofessoresSelecionadas] = useState({});
    const [selectprofessor, setSelectprofessor] = useState('');
    const [professores_total, setprofessoresTotal] = useState({});

    useEffect(() => {
        if (categoria != null && categoria.professores != undefined) {
            let professores_selecionadas_ = {};
            for (let i = 0; i < categoria.professores.length; i++) {
                professores_selecionadas_[categoria.professores[i].id] = categoria.professores[i];
            }
            setprofessoresSelecionadas(professores_selecionadas_);
        }
    }, [categoria]);


    useEffect(() => {

        let professores_total_ = {};
        for (let i = 0; i < professores_all.length; i++) {
            professores_total_[professores_all[i].id] = professores_all[i];
        }
        setprofessoresTotal(professores_total_);

    }, []);

    const number_questoes=new Array(100).fill(0);
    console.log(number_questoes)


    return (
        categoria != null && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => {
                setShowModal(false);
                setprofessoresSelecionadas({});
            }}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Categoria {categoria.name}<br />
            </Modal.Title></Modal.Header>
            <Modal.Body>
                <p>Selecione um ou mais elaboradores que deverão cadastrar questões dessa categoria</p>

                <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Elaboradores <Attention /></Form.Label>
                        <select className="form-select " onChange={(e) => {
                            if (e.target.value != '') {
                                let professores_selecionadas_ = { ...professores_selecionadas };
                                let professores = { ...professores_total };
                                delete professores[e.target.value];
                                professores_selecionadas_[e.target.value] = { ...professores_total[e.target.value] };
                                professores_selecionadas_[e.target.value].numero_questoes='';
                                setprofessoresSelecionadas(professores_selecionadas_);
                                setprofessoresTotal(professores);
                            }
                        }} value={selectprofessor}>
                            <option value="">Selecione um ou mais elaboradores</option>
                            {Object.values(professores_total).sort((a, b) => a.name - b.name).map((value, index) => (
                                <option
                                    key={index}
                                    value={value["id"]}
                                >{value["name"]} - {value["email"]}</option>
                            ))}
                        </select>
                        {/* <a onClick={()=>{
                            let professores_selecionadas_ = { ...professores_selecionadas };
                            Object.values(professores_total).map((value, index) => {
                                professores_selecionadas_[value.id]=value;
                            })
                            setprofessoresSelecionadas(professores_selecionadas_);
                            setprofessoresTotal({});

                        }} style={{textAlign:'right',fontSize:15,marginTop:10,cursor:'pointer',textDecoration:'none'}} className="link-success">Selecionar todos as professores</a> */}

                    </div>
                </InputGroup>

                <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Número de questões</th>


                                            <th data-priority="3">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(professores_selecionadas).sort((a, b) => a.created_at - b.created_at).map((item, id) => (
                                            <tr key={id}>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>
                                                    <select className="form-select " onChange={(e) => {
                                                        let professores={...professores_selecionadas}
                                                        professores[item.id].numero_questoes=e.target.value;
                                                        setprofessoresSelecionadas(professores)
                                                    }} value={item.numero_questoes}>
                                                        <option
                                                                
                                                                value={''}
                                                            >Não especificar</option>
                                                        {number_questoes.map((value, index) => (
                                                            <option
                                                                key={index}
                                                                value={index+1}
                                                            >{index+1}</option>
                                                        ))}
                                                    </select>

                                                </td>


                                                <td>
                                                    <span
                                                        onClick={(e) => {
                                                            let professores = { ...professores_total };
                                                            professores[item.id] = { ...professores_selecionadas[item.id] };

                                                            let professores_selecionadas_ = { ...professores_selecionadas };
                                                            delete professores_selecionadas_[item.id];

                                                            setprofessoresSelecionadas(professores_selecionadas_);
                                                            setprofessoresTotal(professores);
                                                        }}
                                                        className="inline-flex align-center jcc bg-danger icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Deletar elaborador"
                                                    >
                                                        <MdDelete size={17} color="white" />
                                                    </span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {Object.values(professores_selecionadas).length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhum elaborador selecionado</p>

                            </div>
                        </div>}
                    </div>

                </InputGroup>


                {<div className="w100 inline-flex jcsb margin-top-1">
                    {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                    <DefaultButton
                        bg="secondary"
                        text="Fechar"
                        icon={<RiCloseFill />}
                        onClick={() => {
                            setShowModal(false);
                            setprofessoresSelecionadas({});
                        }}
                    />
                    {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                    <DefaultButton
                        type="button"
                        disabled={Object.values(professores_selecionadas).length == 0}

                        onClick={() => {
                            OnFinish(Object.values({ ...professores_selecionadas }).sort((a, b) => a.created_at - b.created_at), categoria);
                            setShowModal(false);

                            setprofessoresSelecionadas({});

                        }}
                        bg={Object.values(professores_selecionadas).length > 0 ? "confirm" : "secondary"}
                        text="Salvar"
                    />
                </div>}

            </Modal.Body>
        </Modal>

    );
}

export default ModalDemanda;