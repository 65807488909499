




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL as url } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdOutlineRule, MdQuestionAnswer, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import TabPanel from "../ReusableComponents/TabPanel";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, IconButton, Snackbar, Tab, Tabs } from "@material-ui/core";
import PDF_IMAGE from '../../assets/images/pdf.png';
import './modal.css';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';
import SweetAlert from 'react-bootstrap-sweetalert';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'react-bootstrap/Modal';


class ModalRespostaRecurso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/recursos',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            faculdade: {},
            recurso: {},
            show_more: false,

            activeTab: 0,
            showModal: false,
            resposta: '',
            show_success: false,

            files: [],
            files_images: [],
            loading_files: false,
            status: '',
            statusQuestao: null
        };
        this.inputRef = React.createRef();

    }

    salvar(e) {

        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('resposta', this.state.resposta);
        form.append('status', this.state.status);
        form.append('anulada', this.state.statusQuestao == null ? 'N' : 'A');
        form.append('questao_id', this.props.questao_id);


        this.state.files.map((file, id) => {
            if (file.id == undefined) {
                form.append('files[]', file);
            }
            else {
                form.append('files_exists[]', file.id);
            }
        })



        fetch(`${url}api/professor/store_resposta_recursos_questao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, show_success: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }






    







    render() {
        return (
            <Modal
                show={this.props.showModal == true}
                dialogClassName="modal_width"
                onHide={() => this.props.setShowModal(false)}
            >
                <Modal.Header closeButton closeLabel=''><Modal.Title>Responder recurso(s)<br /></Modal.Title></Modal.Header>

                <Modal.Body>

                    {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                        <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                        </div>
                        <br />

                    </div>}
                    <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>

                        <div className="flex fdcolumn flex1 margin-bottom ">
                            <Form.Label >Resposta <Attention /></Form.Label>
                            <Input
                                placeholder="Escreva a resposta dos recursos"

                                value={this.state.resposta}
                                onChange={(e) => this.setState({ resposta: e.target.value })}
                                required
                                as="textarea"
                            />
                        </div>

                        <div className="flex fdcolumn flex1 margin-bottom ">
                            <Form.Label >Status <Attention /></Form.Label>
                            <div >
                                <Form.Check

                                    inline
                                    label="Deferido"
                                    name="group1"
                                    type={'radio'}
                                    id={`inline-1`}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        console.log(e.target.checked);
                                        this.setState({ status: 'deferido' })
                                    }}
                                    checked={this.state.status == 'deferido'}

                                />
                                <Form.Check
                                    inline
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        console.log(e.target.checked);
                                        this.setState({ status: 'indeferido' })
                                    }}
                                    label="Indeferido"
                                    checked={this.state.status == 'indeferido'}
                                    name="group1"
                                    type={'radio'}
                                    id={`inline-2`}
                                />

                            </div>
                        </div>

                        <div className="flex fdcolumn flex1 margin-bottom ">
                            <Form.Label >Anular Questão? <Attention /></Form.Label>
                            <div >
                                <Form.Check

                                    inline
                                    label="Sim"
                                    name="group2"
                                    type={'radio'}
                                    id={`inline-1`}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        console.log(e.target.checked);
                                        this.setState({ statusQuestao: 'A' })
                                    }}
                                    checked={this.state.statusQuestao == 'A'}

                                />
                                <Form.Check
                                    inline
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        console.log(e.target.checked);
                                        this.setState({ statusQuestao: null })
                                    }}
                                    label="Não"
                                    checked={this.state.statusQuestao == null}
                                    name="group2"
                                    type={'radio'}
                                    id={`inline-2`}
                                />

                            </div>
                        </div>

                        <div className="flex fdcolumn flex1 margin-bottom ">
                            <Form.Label >Imagens/arquivos de resposta (Máximo 5 arquivos)</Form.Label>
                            <Input
                                type="file"
                                accept="image/*,application/pdf"
                                multiple
                                onChange={(e) => {
                                    this.setState({ loading_files: true });
                                    let files = [...e.target.files];
                                    let files_images = [...this.state.files_images];

                                    console.log(files);
                                    // SetInputValue(file);

                                    // PreviewImage(file);
                                    // (props.ImageChange && props.ImageChange(file));
                                    if (files && FileReader) {

                                        files.map((file, id) => {
                                            files_images.push(URL.createObjectURL(file));

                                        })
                                        let files_actual = [...this.state.files, ...files];
                                        this.setState({ loading_files: false, files: files_actual.filter((item, id) => id < 5), files_images: files_images.filter((item, id) => id < 5) });
                                    }
                                    this.inputRef.current.value = '';
                                }}
                                ref={this.inputRef}
                            />
                            <div className='row' style={{ marginRight: 0, marginLeft: 0 }}>
                                {this.state.files.map((item, id) => (
                                    <div key={id} style={{ width: 100, marginRight: 10, marginTop: 10, position: 'relative' }}>
                                        {!item.name.includes('.pdf') && <img
                                            src={this.state.files_images[id]}
                                            style={{ height: 75, width: 100, objectFit: 'contain' }}
                                        />}
                                        {item.name.includes('.pdf') && <img
                                            src={PDF_IMAGE}
                                            style={{ height: 75, width: 100, objectFit: 'contain' }}
                                        />}
                                        <div style={{ width: 100 }}>
                                            <p style={{ marginBottom: 0, textAlign: 'center', marginTop: 5, fontSize: 11 }}>{item.name}</p>
                                        </div>
                                        <IconButton
                                            style={{ background: 'rgba(0,0,0,0.5)', position: 'absolute', top: 0, right: 0, width: 20, height: 20 }}
                                            id="remove-button"
                                            size="small"
                                            onClick={() => {
                                                let files = this.state.files.filter((item2, id2) => id != id2);

                                                let files_images = this.state.files_images.filter((item2, id2) => id != id2);

                                                this.setState({ files, files_images });

                                            }}
                                        >
                                            <CloseIcon style={{
                                                width: "0.7em",
                                                color: "white"
                                            }} />
                                        </IconButton>
                                    </div>
                                ))}

                            </div>

                        </div>

                        {this.state.loading_save == false && <div className="w100 inline-flex justify-content-end margin-top-1">

                            <DefaultButton
                                type="submit"
                                bg="confirm"
                                text="Salvar resposta"
                                disabled={this.state.loading_save}
                                loadingtext="Salvando resposta"
                                loading={this.state.loading_save}
                            />
                        </div>}

                        {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-end align-items-center margin-top-1" >
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                        </div></div>}
                    </Form>



                    <SweetAlert
                        success
                        title={"Sucesso"}
                        onConfirm={() => {
                            this.setState({ show_success: false });
                            this.props.setShowModal(false)
                            // this.delete_banner(this.state.banner_to_delete);
                        }}
                        onCancel={() => {
                            this.setState({ show_success: false });
                            this.props.setShowModal(false)

                        }}
                        show={this.state.show_success}
                        confirmBtnText='Ok'
                        confirmBtnBsStyle="success"

                    >
                        Resposta salva com sucesso!
                    </SweetAlert>
                </Modal.Body>
            </Modal>



        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);



export default connect(mapsStateToProps, { logout })(ModalRespostaRecurso);


