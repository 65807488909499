import React, { useEffect, useState } from 'react';
import { URL } from '../variables';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header';
import Aside from '../SideBar/Aside';

import '../SideBar/styles/App.scss';
import { mudarDados } from '../actions/AppActions';
import { Route, Switch } from 'react-router';

import Request from '../Request';
import Faculdades from './Faculdades/Faculdades';
import CriarFaculdade from './Faculdades/CriarFaculdade';
import EditarFaculdade from './Faculdades/EditarFaculdade';

import CategoriasQuestoes from './CategoriasQuestoes/CategoriasQuestoes';
import CriarCategoria from './CategoriasQuestoes/CriarCategoria';
import EditarCategoria from './CategoriasQuestoes/EditarCategoria';
import Questoes from './CategoriasQuestoes/Questoes';
import CriarQuestao from './CategoriasQuestoes/CriarQuestao';
import EditarQuestao from './CategoriasQuestoes/EditarQuestao';


import DashboardInstituicao from './Faculdades/Dashboard';

import Demandas from './Demandas/Demandas';
import CriarDemanda from './Demandas/CriarDemanda';
import EditarDemanda from './Demandas/EditarDemanda';
import ShowDemanda from './Demandas/ShowDemanda';
import CriarAtribuicoes from './Demandas/CriarAtribuicoes';
import Recursos from './Recursos/Recursos';
import ShowRecurso from './Recursos/ShowRecurso';
import QuestoesDisciplina from './CategoriasQuestoes/QuestoesDisciplina';
import QuestoesRecurso from './Recursos/QuestoesRecurso';





const Home = (props) => {
    const toggled = useSelector(store => store.AppReducer.toggled)
    const collapsed = useSelector(store => store.AppReducer.collapsed)
    const user = useSelector(store => store.AppReducer.user);
    const token = useSelector(store => store.AppReducer.token);
    const adminAsUser = useSelector(store => store.AppReducer.adminAsUser);

    const dispatch = useDispatch();
    const [loading_home, setLoadingHome] = useState(false);
    const [image, setImage] = useState(true);
    // const [toggled, setToggled] = useState(false);
    const handleCollapsedChange = (checked) => {
        dispatch(mudarDados({ collapsed: checked }));

    };

    const GetRole = async () => {
        let response = await Request.Post(`faculdade/auth/get_user`)
        if (!response) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            dispatch({type:'logout',payload:{}});
        }
        else if (response.message == "Unauthenticated.") {
            localStorage.removeItem('token'); localStorage.removeItem('user');
            dispatch({type:'logout',payload:{}});
            return;
        }
        else{
            dispatch({type:'login',payload:{token:token,user:response['user']}});
           
        }
        
    }

    const handleImageChange = (checked) => {
        setImage(checked);
    };

    const handleToggleSidebar = (value) => {
        // setToggled(value);
        dispatch(mudarDados({ toggled: value }));
    };

    

    useEffect(() => {
        const event = (e) => {
            if (window.innerWidth <= 768) {
                dispatch(mudarDados({ toggled: false, collapsed: false }));

            }
            else {

                dispatch(mudarDados({ toggled: true, collapsed: true }));

            }
        };
        window.addEventListener('resize', event)
        if (window.innerWidth <= 768) {
            dispatch(mudarDados({ toggled: false, collapsed: false }));
        }
        else {
            dispatch(mudarDados({ toggled: true, collapsed: true }));
        }
        GetRole();

        return () => {
            window.removeEventListener('resize', event);

            // Anything in here is fired on component unmount.
        }
    }, [])

    const marginLeft = (toggled==false || window.innerWidth<=768)?0:(collapsed==false?270:80);

    return (
        <div className={`app ${toggled ? 'toggled' : ''}`}>
            <Aside
                image={image}
                collapsed={collapsed}
                toggled={toggled}

                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}

            />
            <Header
                image={image}
                toggled={toggled}
                collapsed={collapsed}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
                handleImageChange={handleImageChange}
            />
            <div className="content-page" >
                <div className="content" style={{marginLeft: marginLeft,transition: 'all 0.3s ',paddingBottom:100,background:'#f8f8fa'}}>
                    <div className="container-fluid">
                        <Switch>
                        <Route exact path={`${props.match.url}disciplinas`} render={(props2) => <CategoriasQuestoes {...props2} />} />
                        <Route exact path={`${props.match.url}disciplinas/show/:id`} render={(props2) => <QuestoesDisciplina {...props2} />} />

                            <Route exact path={`${props.match.url}`} render={(props2) => <Demandas {...props2} />} />
                            <Route exact path={`${props.match.url}demandas/show/:id`} render={(props2) => <ShowDemanda {...props2} />} />

                            <Route exact path={`${props.match.url}atribuicao/questoes/:demanda_id`} render={(props2) => <Questoes {...props2} />} />
                            <Route exact path={`${props.match.url}atribuicao/questoes/register/:demanda_id`} render={(props2) => <CriarQuestao {...props2} />} />
                            <Route exact path={`${props.match.url}atribuicao/questoes/edit/:id`} render={(props2) => <EditarQuestao {...props2} />} />

                            <Route exact path={`${props.match.url}recursos`} render={(props2) => <QuestoesRecurso {...props2} />} />
                            <Route exact path={`${props.match.url}recursos/:id`} render={(props2) => <Recursos {...props2} />} />

                            <Route exact path={`${props.match.url}recursos/show/:id`} render={(props2) => <ShowRecurso {...props2} />} />



                        </Switch>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Home;