import React, {useState, useRef, useEffect} from "react";

import {FormControl as Input, Button} from "react-bootstrap";

import {ImImages} from "react-icons/im";
import {RiDeleteBin5Line} from "react-icons/ri";

import { STORAGE_URL } from "../../variables";

const ImageLoaderInput = (props) => {

    const [image, SetInputValue] = useState();
    const [has_image, SetHasImage] = useState(false);

    const [path_loaded, SetPathLoad] = useState(false);

    const input_image = useRef(null);
    const image_holder = useRef(null);

    const PreviewImage = (file) => {
        let image = file;
        if (image && FileReader) {
            let filereader = new FileReader();

            filereader.onload = () => {
                image_holder.current.style.backgroundImage = `url(${filereader.result})`;
                SetHasImage(true);
            };
            filereader.readAsDataURL(image);
        }
    };

    const RemovePreviewImage = () => {
        SetInputValue("");
        SetHasImage(false);

        (props.ImageChange && props.ImageChange(""));

        image_holder.current.style.backgroundImage = "";
        input_image.current.value = "";
    };

    const LoadPathImage = () => {
        image_holder.current.style.backgroundImage = `url(${STORAGE_URL + props.path})`;
        SetHasImage(true);
    }
    
    useEffect(() => {
        if (props.path && !path_loaded) {
            SetPathLoad(true);
            LoadPathImage();
        }
    });

    return(
        <div style={{width:props.width_==null? "fit-content":props.width_}} className="margin-bottom">
            <Input 
                type="file" 
                accept="image/*"
                className="hide"
                onChange={(e) => {
                    let file = e.target.files[0];
                    SetInputValue(file);
                    PreviewImage(file);
                    (props.ImageChange && props.ImageChange(file));
                }}
                ref={input_image}
            />
            <div
                onClick={() => input_image.current.click()}
                className="input-image-box align-center jcc flex"
                ref={image_holder}
                style={props.width_==null?{ cursor:'pointer', backgroundRepeat:'no-repeat',  backgroundSize: 'contain', backgroundPositionX:'center'}:{cursor:'pointer',width:props.width_, backgroundPositionX:'center',  backgroundRepeat:'no-repeat', backgroundSize: 'contain',height:props.height_}}
            >
                <ImImages size={50} color="gray" className={has_image ? "hide" : ""}/>
            </div>
            {/* <Button variant="danger" className={"w100 align-center jcc flex rounded-0 " + (has_image ? "" : "hide")} onClick={() => RemovePreviewImage()}>
                <RiDeleteBin5Line/>
                Deletar
            </Button> */}
        </div>
    );
}

export default ImageLoaderInput;