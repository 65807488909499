
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ModalDemanda from './ModalDemanda';
import { BsFillEyeFill } from 'react-icons/bs';


class CriarAtribuicoes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/demandas',
            loading_screen: false,
            roles: [],
            name: '',
            descricao: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,

            fim: moment().add(1, 'day'),
            hora_inicio: moment(),
            hora_fim: moment().add(1, 'hour'),
            inicio: moment(),
            categorias: [],
            professores: [],

            categoria_search: '',
            show_autocomplete_categoria: false,
            loading_search_categoria: false,
            categorias_select: {},
            showModal: false,
            categoriaSelect: null,
            demanda:{},
            demandas_professores:[]
        };


    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });

        // if (Object.values(this.state.categorias_select).length == 0) {
        //     this.setState({ loading_save: false, msgErro: 'A demanda deve ter pelo menos uma categoria selecionada' });
        //     return;
        // }
       
        fetch(`${URL}api/faculdade/store_atribuicoes`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                demanda_id:this.state.demanda.id,
                categorias:Object.values(this.state.categorias_select)
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_data(this.props.match.params.id);
    }


    get_data(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_data_demanda/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let categorias_select={};
                    for(let i=0;i<resp.demandas_professores.length;i++){
                        resp.demandas_professores[i].professor.numero_questoes=resp.demandas_professores[i].numero_questoes??'';
                        if(categorias_select[resp.demandas_professores[i].categoria.id]==undefined){
                            categorias_select[resp.demandas_professores[i].categoria.id]={...resp.demandas_professores[i].categoria,professores:[resp.demandas_professores[i].professor]}
                        }
                        else{
                            
                            categorias_select[resp.demandas_professores[i].categoria.id].professores.push(resp.demandas_professores[i].professor);
                        }
                    }

                    this.setState({
                        loading_screen: false, categorias: resp.categorias,
                        professores: resp.professores,
                        demanda: resp.demanda,
                        categorias_select:categorias_select
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    select_categoria(item) {
        let categorias = { ...this.state.categorias_select };
        categorias[item.id] = item;
        this.setState({ categoria_search: '', categorias_select: categorias })
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={"/demandas/show/"+this.state.demanda.id} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Adicionar/Editar Atribuições</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Adicione/Edite atribuições à demanda "{this.state.demanda.name}"</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}

                                {this.state.showModal == true && <ModalDemanda showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} categoria={this.state.categoriaSelect} professores_all={this.state.professores} OnFinish={(professores, categoria) => {
                                    categoria.professores = professores;
                                    this.select_categoria(categoria);
                                }} />}

                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    {/* <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Demanda:  <span style={{fontWeight:'normal'}}>{this.state.demanda.name}</span></Form.Label>
                                            
                                        </div>
                                    </InputGroup> */}
                                   
                                   

                                    {<InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Categorias <Attention /></Form.Label>
                                            <Input
                                                placeholder="Pesquise uma categoria de questão para adicionar a esta demanda"
                                                value={this.state.categoria_search}
                                                onChange={(e) => this.setState({ categoria_search: e.target.value })}
                                                onBlur={() => {
                                                    this.timeout_ = setTimeout(() => {
                                                        this.setState({ show_autocomplete_categoria: false });

                                                    }, 500)
                                                }}
                                                onFocus={() => {

                                                    this.setState({ show_autocomplete_categoria: true })
                                                }}
                                            />
                                            {this.state.show_autocomplete_categoria == true && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 30, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '15vh', top: 70 }}>
                                                {this.state.loading_search_categoria == false && this.state.categorias.map((item, id) => (
                                                    <div key={id} onClick={() => {
                                                        if(this.state.categorias_select[item.id]!=undefined){
                                                            console.log('vai')
                                                            this.setState({ categoriaSelect: {...this.state.categorias_select[item.id]}, showModal: true })
                                                        }
                                                        else{
                                                            this.setState({ categoriaSelect: {...item}, showModal: true })
                                                        }
                                                    }} className="opcao_autocomplete">
                                                        {item.name}
                                                    </div>
                                                ))}
                                                {this.state.loading_search_categoria == false && this.state.categorias.length == 0 &&
                                                    <div className="opcao_autocomplete">
                                                        Nenhuma categoria encontrada com este termo
                                                    </div>
                                                }
                                                {this.state.loading_search_categoria == true &&
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <div className="spinner-border text-primary" role="status"  >
                                                        </div></div>}
                                            </div>}
                                        </div>
                                    </InputGroup>}
                                    <Form.Label >Categorias selecionadas</Form.Label>
                                    <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom col-12">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="table" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Categoria</th>

                                                                <th>Elaboradores</th>



                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.values(this.state.categorias_select).map((item, id) => (
                                                                <tr key={id}>
                                                                    <td ><p >{item.name}</p></td>
                                                                    <td >
                                                                        {item.professores.map((item2,id2)=>(
                                                                            <span key={id2}><b>{item2.name}</b>  - {item2.email}
                                                                            <p style={{marginBottom:5}}>Número de questões: {item2.numero_questoes==''?'Não especificado':item2.numero_questoes}</p>
                                                                            {/* {id2+1!=item.professores.length && <br/>} */}
                                                                            </span>
                                                                        )) }
                                                                        </td>
                                                                    
                                                                    
                                                                    <td style={{ minWidth: 90 }}>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ categoriaSelect: {...item}, showModal: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: 5 }}
                                                                            title="Gerenciar categoria"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let categorias_select = { ...this.state.categorias_select };
                                                                                delete categorias_select[item.id];
                                                                                this.setState({ categorias_select });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Deletar categoria"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {Object.values(this.state.categorias_select).length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhuma categoria adicionada</p>

                                                </div>
                                            </div>}
                                        </div>

                                    </InputGroup>
                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to={"/demandas/show/"+this.state.demanda.id}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar atribuições"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando demanda"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarAtribuicoes);


